import React from 'react';
import { Container, Spinner } from 'reactstrap';

import Code from '../services/Code';
import Event from '../services/Event';
import Firebase from '../services/Firebase';
import Karmuh from '../services/Karmuh';
import Log from '../services/Log';
import Url from '../services/Url';
import Constants from '../Shared/Constants';
import Footer from '../Shared/Footer';
import Menu from '../Shared/Menu';
import Message from '../Shared/Message';
import Meta from '../Shared/Meta';
import ProfileInfo from '../Shared/ProfileInfo';
import Receipts from '../Shared/Receipts';
import Separator from '../Shared/Separator';
import SubscriptionInfo from '../Shared/SubscriptionInfo';
import TeamInfo from '../Shared/TeamInfo';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      message: null,
      name: null,
      photo: null,
      profile: null,
      profileLoading: true,
    };
  }

  componentDidMount() {
    this.pullUserProfile();
  }

  clearAlerts = () => {
    this.setState({
      error: null,
      message: null,
    });
  }

  setErrorFromChild = (error) => {
    this.setState({
      error: error,
    });
  }

  setMessageFromChild = (message) => {
    this.setState({
      message: message,
    });
  }

  pullUserProfile = async () => {
    const name = Firebase.auth().currentUser.displayName;
    const photo = Firebase.auth().currentUser.photoURL;

    try {
      const karmuh = new Karmuh();
      await karmuh.getProfile();

      if (karmuh.error) {
        Event.error(karmuh.error);
        this.setState({
          error: karmuh.error,
          name: name,
          photo: photo,
          profileLoading: false,
        });
      } else {
        this.setState({
          name: name,
          photo: photo,
          profile: karmuh.response,
          profileLoading: false,
        });
      }
    } catch (e) {
      Event.error(Constants.GeneralApiError);
      this.setState({
        error: Constants.GeneralApiError,
        name: name,
        photo: photo,
        profileLoading: false,
      });
    }
  }

  render() {
    Log.message(`Loading Account`);

    // Set up display and message variables
    let msg = <span />;
    let displayContent = null;

    if (this.state.profileLoading) {
      displayContent =
        <div>
          <Spinner />
          <h5 className="white">Just a minute while we get your data..</h5>
        </div>;
    } else {
      if (this.state.error || this.state.message) {
        // Render the message
        const color = this.state.message
          ? 'success'
          : this.state.error
            ? 'danger'
            : 'info';

        const text = this.state.error
          ? Code.map(this.state.error)
          : this.state.message
            ? this.state.message
            : '';
        msg = <Message color={color} message={text} onClose={this.clearAlerts} />;
      }

      // We got data back, show things

      const userDisplayContent = this.state.profile.installation
        ? <>
            <ProfileInfo profile={this.state.profile} />
            <TeamInfo profile={this.state.profile} />
            <SubscriptionInfo profile={this.state.profile} bubbleError={this.setErrorFromChild} bubbleSuccess={this.setMessageFromChild} />
            <Receipts profile={this.state.profile} />
          </>
        : <>
            <div className="mb-4">
              <a href={Url.addToSlack(this.state.profile.team)} id="add-to-slack" target="_blank" rel="noreferrer">
                <img
                  alt="Add to Slack"
                  src="https://platform.slack-edge.com/img/add_to_slack.png"
                  srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                />
              </a>
            </div>
            <h5 className="white">Add {Constants.AppName} to your Slack workspace and start building your {Constants.AppName} now!</h5>
            <p className="white">(If you've already added {Constants.AppName} to your workspace, please reload this page)</p>
          </>;

      const userProfilePhoto = this.state.photo
        ? <img alt="User profile" className="profile-photo" src={this.state.photo} />
        : <></>;

      displayContent =
        <div>
          <h4>Welcome, {this.state.name} {userProfilePhoto}</h4>
          <br />
          {userDisplayContent}
        </div>;
    }

    return (
      <>
        <Meta
          pageName="Your Account"
          ogTitle={Constants.AppName}
          ogDescription={Constants.Tagline}
        />
        {msg}
        <Menu showSignOut={true} />

        <div className="section-hero section-shaped">
          <div className="shape shape-style-2 shape-default"></div>

          <Container className="body-content">
            <section>
              <div>
                {displayContent}
              </div>
            </section>
          </Container>

          <Separator color="gray" />

        </div>
        <Footer />
      </>
    );
  }
}

export default Account;
