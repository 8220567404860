import Dates from '../services/Dates';
import Constants from '../Shared/Constants';

const Subscription = {
  daysRemaining: (subscription) => {
    const trialEnding = Subscription.trialEnd(subscription);
    return Math.abs(Dates.difference(new Date(), trialEnding));
  },
  inTrial: (subscription) => {
    if (!subscription) {
      return false;
    }

    if (!subscription.created) {
      return false;
    }

    const trialBeginning = new Date(subscription.created);
    const trialEnding = Subscription.trialEnd(subscription);
    const isInTrial = Dates.inBetween(trialBeginning, new Date(), trialEnding);

    return isInTrial;
  },
  nextBillDate: (subscription, payment) => {
    const now = new Date();
    const trialEnd = Subscription.trialEnd(subscription);
    const paymentUpdated = new Date(payment.updated);

    const billingStart = paymentUpdated < trialEnd
      ? trialEnd
      : paymentUpdated;

    let nextBill = Dates.convertToThisMonth(billingStart);

    if (Dates.isBefore(nextBill, now)) {
      nextBill = Dates.addOneMonth(nextBill);
    }

    return nextBill;
  },
  trialEnd: (subscription) => {
    const trialBeginning = new Date(subscription.created);
    const trialDurationDays = subscription.duration
      ? subscription.duration
      : Constants.DaysFree;
    const trialDurationSeconds = trialDurationDays * 24 * 60 * 60;
    const trialEnding = new Date(trialBeginning.getTime() + (trialDurationSeconds * 1000));
    return trialEnding;
  },
};

export default Subscription;
