import React, { useEffect, useState } from 'react';

import Firebase from '../services/Firebase';
import Log from '../services/Log';

export const AuthContext = React.createContext();

export const Auth = ({ children }) => {
  // Data object is { loading: bool, user: object|null }
  const [data, setData] = useState({ loading: true, user: null });

  useEffect(() => {
    Firebase.auth().onAuthStateChanged((user) => {
      Log.message(`Auth state done loading, setting user to ${JSON.stringify(data)}`);
      if (data.loading) {
        setData({
          loading: false,
          user: user,
        });
      }
    });
  }, [data]);
  return (
    <AuthContext.Provider value={{data}}>
      {children}
    </AuthContext.Provider>
  );
};
