import Karmuh from './Karmuh';
import Constants from '../Shared/Constants';

const userScopes = [
  'identity.basic',
  'identity.email',
  'identity.team',
].join(',');

const Url = {
  addToSlack: (team) => {
    const karmuhOauths = `${(new Karmuh()).url()}/oauths`;

    const params = team
      ? [
          `team=${team}`,
        ].join('&')
      : '';

    const paramString = params.length
      ? `?${params}`
      : ``;

    return `${karmuhOauths}${paramString}`;
  },
  slackConnectProfileSignIn: () => {
    const karmuhConnect = `${(new Karmuh()).url()}/connect`;

    const params = [
      `user_scope=${userScopes}`,
      `client_id=${Constants.ClientId}`,
      `redirect_uri=${encodeURIComponent(karmuhConnect)}`,
    ].join('&');

    return `https://slack.com/oauth/v2/authorize?${params}`;
  },
  slackSignIn: () => {
    const karmuhTokens = `${(new Karmuh()).url()}/tokens`;

    const params = [
      `user_scope=${userScopes}`,
      `client_id=${Constants.ClientId}`,
      `redirect_uri=${encodeURIComponent(karmuhTokens)}`,
    ].join('&');

    return `https://slack.com/oauth/v2/authorize?${params}`;
  },
};

export default Url;
