import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import Connections from '../Shared/Connections';
import Constants from '../Shared/Constants';

class ProfileInfo extends React.Component {
  render() {
    const profile = this.props.profile;

    const globalKarmuhDisplay = profile.points
      ? <div>
          {Constants.GlobalKarmuhName} {Constants.AppName}: <strong>{profile.points}</strong>
          <br /><br />
        </div>
      : <></>;

    return (
      <>
        <Card className="shadow team-info">
          <CardBody>
            <CardTitle>
              <h5>Profile Info</h5>
            </CardTitle>
            <div className="paragraph">
              {globalKarmuhDisplay}
              <Connections profile={profile} />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ProfileInfo;
