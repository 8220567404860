import React from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import Event from '../services/Event';
import Karmuh from '../services/Karmuh';
import Log from '../services/Log';
import Constants from '../Shared/Constants';
import Meta from '../Shared/Meta';

class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      error: null,
      hash: this.props.match.params.hash,
      loading: true,
    };
  }

  attemptConnection = async () => {
    if (this.state.hash) {
      let decoded = null;
      let decodedObject = null;
      let customerUuid = null;

      try {
        const buf = Buffer.from(this.state.hash, 'base64')
        decoded = buf.toString();
      } catch (e) {
        Event.error(Constants.Base64DecodingError);
        this.setState({
          connected: false,
          error: Constants.Base64DecodingError,
        });
      }

      try {
        decodedObject = JSON.parse(decoded);
      } catch (e) {
        Event.error(Constants.JsonParsingError);
        this.setState({
          connected: false,
          error: Constants.JsonParsingError,
        });
      }

      try {
        customerUuid = decodedObject.customer;
      } catch (e) {
        Event.error(Constants.ConnectionMissingDataError);
        this.setState({
          connected: false,
          error: Constants.ConnectionMissingDataError,
        });
      }

      try {
        // Make the API call to connect the decoded user to the existing user
        Log.message(customerUuid);

        const karmuh = new Karmuh();
        await karmuh.connectAccount(customerUuid);

        if (karmuh.error) {
          Event.error(karmuh.error);
          this.setState({
            connected: false,
            error: karmuh.error
          });
        } else {
          this.setState({
            connected: true,
          });
        }
      } catch (e) {
        Event.error(Constants.ConnectingAccountError);
        this.setState({
          error: Constants.ConnectingAccountError,
        });
      }
    }
  }

  componentDidMount() {
    this.attemptConnection();
  }

  render() {
    Log.message(`Loading Connect`);

    // Error, redirect to error
    if (this.state.error) {
      const errorPath = `/error/${this.state.error}`;
      return (
        <Redirect to={errorPath} />
      );
    }

    // No hash or connected, go to account
    if (!this.state.hash || this.state.connected) {
      const accountPath = `/account`;
      return (
        <Redirect to={accountPath} />
      );
    }

    // Default to spinner
    let displayContent = null;
    displayContent =
      <>
        <Spinner /><br />
        Connecting your accounts.
      </>;

    return (
      <>
        <Meta
          pageName="Connect"
          ogTitle="Connect"
          ogDescription={"Connect with " + Constants.AppName}
        />

        <div className="full-height section-hero section-shaped">
          <div className="shape shape-style-4 shape-default"></div>
          <Container className="body-content">
            <div className="jumbotron jumbotron-clear align-items-center justify-content-center text-center">
              {displayContent}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Connect;
