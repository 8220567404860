import React from 'react';
import { Button, Card, CardBody, Container, TabContent, TabPane } from 'reactstrap';

import Log from '../services/Log';
import Constants from '../Shared/Constants';
import Footer from '../Shared/Footer';
import Menu from '../Shared/Menu';
import Meta from '../Shared/Meta';
import Separator from '../Shared/Separator';

const testAnim = 'animations/test-anim.json'

class Contact extends React.Component {
  render() {
    Log.message(`Loading Contact`);

    return (
      <>
        <Meta
          pageName="Contact Us"
          ogTitle="Contact"
          ogDescription={Constants.ContactTagline}
        />
        <Menu />
        <div className=" section-hero section-shaped">
          <div className="shape shape-style-2 shape-default"></div>

          <Container className="body-content">
            <section>
              <h1>Contact Us</h1>
              <p>
                Have a question, found a bug, or wish {Constants.AppName} did something
                more? Let us know!
              </p>
              <br />
              <br />

              <Card className="shadow contact-us">
                <CardBody>
                  <TabContent className="flex">
                    <TabPane className="flex">
                      <div className="left">
                        <h3>Email</h3>
                        <p className="description">
                          Send us a message! We usually respond within a few hours.
                        </p>
                        <Button color="primary" type="button" href={Constants.MailToEmail} id="contact-email">
                          Email Us
                        </Button>
                      </div>
                      <div className="right">
                        <lottie-player src={testAnim}
                          background="transparent" speed="1"
                          style={{ width: "200px", height: "160px" }} loop autoplay
                        ></lottie-player>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>

            </section>

            <br /><br /><br />
            <br /><br /><br />

          </Container>

          <Separator color="gray" />
        </div>

        <Footer />
      </>
    );
  }
}

export default Contact;
