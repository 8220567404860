import React from 'react';
import { Card, CardBody, CardTitle, Spinner, Table } from 'reactstrap';

import Code from '../services/Code';
import Event from '../services/Event';
import Karmuh from '../services/Karmuh';
import Subscription from '../services/Subscription';
import Constants from '../Shared/Constants';

class Receipts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      receipts: null,
      receiptsLoading: true,
    };
  }

  componentDidMount() {
    this.pullUserReceipts();
  }

  pullUserReceipts = async () => {
    try {
      const karmuh = new Karmuh();
      await karmuh.getReceipts();

      if (karmuh.error) {
        Event.error(karmuh.error);
        this.setState({
          error: karmuh.error,
          receiptsLoading: false,
        });
      } else {
        this.setState({
          receipts: karmuh.response,
          receiptsLoading: false,
        });
      }
    } catch (e) {
      Event.error(Constants.GeneralApiError);
      this.setState({
        error: Constants.GeneralApiError,
        receiptsLoading: false,
      });
    }
  }

  render() {
    const profile = this.props.profile;

    if (!profile.installation) {
      return (<></>);
    }

    // Figure out if subscription is in trial
    const subIsTrial = Subscription.inTrial(profile.subscription);

    if (!(profile.admin && !subIsTrial)) {
      return (<></>);
    }

    // Free plans won't have receipts
    if (profile.subscription.plan === Constants.SubscriptionPlanFree) {
      return (<></>);
    }

    // Also show them their receipts
    // If admin && subscription is not in trial
    // -- then check for payments and show a table with
    // either no payments or a list of payments
    const receiptContent = this.state.receiptsLoading
      ? <Spinner />
      : this.state.error
        ? <p>{Code.map(this.state.error)}</p>
        : this.state.receipts && this.state.receipts.length
          ? <Table className="receipts-table" responsive borderless>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.receipts.map(receipt => (
                  <tr key={receipt.uuid}>
                    <td>
                      <a href={receipt.url} target="_blank" rel="noreferrer">
                        {(new Date(receipt.created)).toLocaleDateString()}
                      </a> ↗️
                    </td>
                    <td>
                      ${receipt.amount.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          : <p>Your receipts will show up here.</p>;

    return (
      <>
        <Card className="shadow subscription-info">
          <CardBody>
            <CardTitle>
              <h5>Receipts</h5>
            </CardTitle>
            <div className="paragraph">
              {receiptContent}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Receipts;
