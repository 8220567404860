import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';

class Links extends React.Component {
  render = (props) => {
    const links = this.props.all
      ? <>
          <NavItem>
            <Link className="nav-link" to="/">Home</Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/contact">Contact</Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/privacy">Privacy</Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/terms">Terms</Link>
          </NavItem>
        </>
      : <>
          <NavItem>
            <Link className="nav-link" to="/">Home</Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/contact">Contact</Link>
          </NavItem>
        </>;

    return (links);
  }
}

export default Links;
