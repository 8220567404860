import React from 'react';
import { Alert } from 'reactstrap';

// message - the text to display
// color - 'success', 'info', 'danger'
// onClose - the function used to bubble up the close
class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  dismiss = () => {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  render() {
    return (
      <>
        <Alert className="alert-message" color={this.props.color} isOpen={this.state.visible && this.props.message.length > 0} toggle={this.dismiss}>
          {this.props.message}
        </Alert>
      </>
    );
  }
}

export default Message;
