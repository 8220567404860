import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './assets/style.css';
import './assets/karmuh-custom.css';

import reportWebVitals from './reportWebVitals';
import Main from './components/Main';
import Event from './services/Event';

ReactDOM.render(
  <React.Fragment>
    <Main />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(Log.message))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(Event.analytics);
