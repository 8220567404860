const Code = {
  map: (code) => {
    let message = '';

    if (Number.isInteger(code)) {
      code = code.toString();
    }

    switch (code) {
      case '101':
      case '102':
      case '106':
      case '107':
      case '108':
      case '112':
      case '113':
      case '116':
      case '117': {
        message = `An internal error has occurred (#${code}).`;
        break;
      }

      case '103': {
        message = `Not authorized to perform this action (#${code}).`;
        break;
      }

      case '104': {
        message = `Something's wrong with the request (#${code}).`;
        break;
      }

      case '105': {
        message = `Your session has expired, please sign in (#${code}).`;
        break;
      }

      case '109': {
        message = `Your request has expired, please try again (#${code}).`;
        break;
      }

      case '110':
      case '111':
      case '114':
      case '118': {
        message = `There was an issue talking to slack, please try again (#${code}).`;
        break;
      }

      case '119': {
        message = `There is already a method of payment on file (#${code}).`;
        break;
      }

      case '120':
      case '121':
      case '124': {
        message = `There was an issue setting up your card, please contact us (#${code}).`;
        break;
      }

      case '123': {
        message = `There was an issue signing you in, please try again (#${code}).`;
        break;
      }

      case '126':
      case '127':
      case '128':
      case '129':
      case '130': {
        message = `There was an issue connecting your account, please try again (#${code}).`;
        break;
      }

      case '131': {
        message =
          `You can only connect accounts on Slack teams that have Karmuh already ` +
          `installed. Please install Karmuh in your new Slack team before you ` +
          `connect your account (#${code}).`;
        break;
      }

      default: {
        message = `An unexpected error has occurred.`;
        break;
      }
    }

    return message;
  },
};

export default Code;
