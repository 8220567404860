import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Jumbotron, Row } from 'reactstrap';

import KarmuhSlackImage from '../assets/karmuh-on-slack.png';
import Log from '../services/Log';
import Url from '../services/Url';
import Chat from '../Shared/Chat';
import Constants from '../Shared/Constants';
import Footer from '../Shared/Footer';
import Menu from '../Shared/Menu';
import Meta from '../Shared/Meta';
import Separator from '../Shared/Separator';

const kPluses = 'animations/k-plus-loop.json'

class Landing extends React.Component {
  render() {
    Log.message(`Loading Landing`);

    return (
      <>
        <Meta
          pageName=""
          ogTitle=""
          ogDescription={Constants.Tagline}
        />
        <Chat />
        <Menu />
        {/* HERO */}
        <section className="section-hero section-shaped">
          <div className="shape shape-style-1 shape-default"></div>
          <div className="container jumbotron jumbotron-clear align-items-center justify-content-center text-center">
            <h1 className="display-1">{Constants.AppNameLowercase}</h1>
            <p className="lead full">{Constants.Tagline}</p>
            <p className="lead">{Constants.TaglineTwo}</p>
            <br /><br />
            <Link to="/login">
              <Button color="primary" type="button" id="hero-signup">{Constants.SignUpText}</Button>
            </Link>
            <br /><br /><br /><br />
            <br /><br /><br /><br />
          </div>
          <Separator />
        </section>

        {/* SLACK DEMO IMAGE */}
        <section className="section-shaped image-section">
          <div className="shape"></div>
          <div className="jumbotron jumbotron-clear align-items-center justify-content-center text-center">
            <img className="hero-image" alt="Karmuh usage on Slack" src={KarmuhSlackImage} />
            <br /><br /><br />
          </div>
          <Separator color="purple" />
        </section>

        {/* HOW IT WORKS */}
        <section className="section-shaped post-image-section">
          <div className="shape shape-default"></div>
          <Container className="how-it-works">
            <br /><br />
            <br /><br />
            <h2>How It Works</h2>
            <br />
            <Jumbotron className="flex">
              <div className="left">
                <h3>Step 1</h3> Add {Constants.AppName} to Slack<br /><br />
                <h3>Step 2</h3> Give {Constants.AppNameLowercase}++ in your messages to show praise, appreciation, or to just simply put a smile on someone's face.<br /><br />
                <h3>Step 3</h3> Watch your team come closer together.<br /><br />
              </div>
              <div className="right">
                <lottie-player class="k-plus-loop" src={kPluses}
                  background="transparent" speed="1"
                  style={{ width: "200px", height: "320px" }} loop autoplay
                ></lottie-player>
              </div>
            </Jumbotron>
          </Container>

          <br /><br /><br /><br />
          <Separator />
        </section>

        {/* ADDING TO YOUR TEAM */}
        <section className="section-shaped adding">
          <div className="shape"></div>
          <Container>

            <br /><br /><br /><br />

            <h2>Adding {Constants.AppName} to your Slack team</h2>
            <p className="description">
              To use {Constants.AppName}, your team needs to use Slack. Once you
              have a Slack team you can add {Constants.AppName} to your team. After
              you've added {Constants.AppName}, you can simply invite {Constants.AppName} to
              any and all channels you want it to listen to. That's it!
            </p>
            <p className="description">
              No need to create accounts, passwords, teach people how to use it, or add
              people to {Constants.AppName} to get started. Simply start
              giving {Constants.AppName}, and we'll do the rest for you.
            </p>

            <br /><br />
            <hr />
            <br /><br />

            <h2>What Should I Do</h2>
            <h4>Adding {Constants.AppName} to Channels</h4>
            <p>{Constants.AppName} only works in channels it's been invited to. Go to the channel you'd like to add it to and type:</p>
            <Card className="code-snippet">
              <span className="left"> /invite <strong>@{Constants.AppNameLowercase}</strong></span>
              <span className="right">&nbsp;</span>
            </Card>

            <h4>Giving {Constants.AppName}</h4>
            <p>
              To give {Constants.AppName} to something, simply add some plusses to the end of that
              thing. You can give {Constants.AppName} to people, you can give {Constants.AppName} to
              things. The more plusses, the more {Constants.AppName}. Check it out:
            </p>
            <Card className="code-snippet">
              <span className="left"><strong>@kimberly++++</strong></span>
              <span className="right">(to give @kimberly 3 {Constants.AppName})</span>
            </Card>
            <Card className="code-snippet">
              <span className="left"><strong>apples++++++</strong></span>
              <span className="right">(to give apples 5 {Constants.AppName})</span>
            </Card>
            <p>
              Did someone do something wrong? Want to make sure they remember it? You can also take
              away some {Constants.AppName}. Here:
            </p>
            <Card className="code-snippet">
              <span className="left"><strong>@steve--</strong></span>
              <span className="right">(to take 1 {Constants.AppName} away from steve)</span>
            </Card>

            <h4>Commands</h4>
            <p>Want to see who has the most {Constants.AppName}? Or the least? Or learn more about what {Constants.AppName} can do?</p>
            <Card className="code-snippet">
              <span className="left">/{Constants.AppNameLowercase} <strong>top users</strong></span>
              <span className="right">(shows top 10 users)</span>
            </Card>
            <Card className="code-snippet">
              <span className="left">/{Constants.AppNameLowercase} <strong>top things</strong></span>
              <span className="right">(shows top 10 things)</span>
            </Card>
            <Card className="code-snippet">
              <span className="left">/{Constants.AppNameLowercase} <strong>top overall</strong></span>
              <span className="right">(shows top 10 overall)</span>
            </Card>
            <Card className="code-snippet">
              <span className="left">/{Constants.AppNameLowercase} <strong>bottom users</strong></span>
              <span className="right">(shows bottom 10 users)</span>
            </Card>
            <Card className="code-snippet">
              <span className="left">/{Constants.AppNameLowercase} <strong>bottom things</strong></span>
              <span className="right">(shows bottom 10 things)</span>
            </Card>
            <Card className="code-snippet">
              <span className="left">/{Constants.AppNameLowercase} <strong>bottom overall</strong></span>
              <span className="right">(shows bottom 10 overall)</span>
            </Card>
            <br /><br /><br /><br />
          </Container>
          <Separator color="green" />
        </section>


        <section className="section-shaped price-section">
          <div className="shape"></div>
          <div className="jumbotron jumbotron-clear align-items-center justify-content-center text-center">


            <div className="pricing-info">
              <h3 className="display-2">Try us <strong>FREE</strong> for {Constants.DaysFree} days!</h3>
              <p>
                No credit card required. Get access to all of our features. <br />
                Cancel anytime.
              </p>
              <br /><br />
              <Row>
                <Col xs="12" sm="6" md="4">
                  <Link to="/login" id="starter-signup" className="no-decoration">
                    <Card className="shadow starter">
                      <CardBody>
                        <CardTitle>
                          <h2>{Constants.TierOneName}</h2>
                        </CardTitle>
                        <p className="description">
                          For teams of up to {Constants.TeamSizeSmallLimit} people
                        </p>
                        <span className="display-3">${Constants.TeamSizeSmallPrice}</span> / mo
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col xs="12" sm="6" md="4">
                  <Link to="/login" id="starter-signup" className="no-decoration">
                    <Card className="shadow growth">
                      <CardBody>
                        <CardTitle>
                          <h2>{Constants.TierTwoName}</h2>
                        </CardTitle>
                        <p className="description">
                          For teams of up to {Constants.TeamSizeLargeLimit} people
                        </p>
                        <span className="display-3">${Constants.TeamSizeLargePrice}</span> / mo
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col xs="12" sm="6" md="4">
                  <Link to="/contact" className="no-decoration">
                    <Card className="shadow enterprise">
                      <CardBody>
                        <CardTitle>
                          <h2>{Constants.TierThreeName}</h2>
                        </CardTitle>
                        <div className="paragraph">
                          <p className="description">
                            Teams greater than {Constants.TeamSizeLargeLimit} people
                          </p>
                          <span className="display-3">Email Us</span>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
              <br /><br /><br />
              <p>
                <Link to="/contact" className="no-decoration">Contact us</Link> for special enterprise, not-for-profit, teacher, and student pricing.
              </p>
              <br /><br /><br />
              <a href={Url.addToSlack()} id="cta-add-to-slack" target="_blank" rel="noreferrer">
                <Button color="primary" type="button">
                  Start your FREE {Constants.DaysFree} day trial!
                </Button>
              </a>
            </div>
          </div>

          <Container>
            <h2>Pricing Info</h2>

            <Card className="pricing-details">
              <h5>What happens when the trial is over? Will I be charged?</h5>
              <p>
                After your trial ends you won't be charged because we don't require a credit
                card to try {Constants.AppName}. You'll receive a couple messages during your
                trial prompting you to upgrade. If you don't upgrade and your trial expires,
                your team members won't be able to give {Constants.AppName} anymore.
              </p>
            </Card>

            <Card className="pricing-details">
              <h5>What if we need more than {Constants.DaysFree} days to try out {Constants.AppName}?</h5>
              <p>
                We're more than happy to extend a trial if you need more time.
                Simply <Link to="/contact" className="no-decoration">contact us</Link>, and we'll make it happen.
              </p>
            </Card>

            <Card className="pricing-details">
              <h5>How do you calculate the number of people on my team I have to pay for? Do I have to pay for everyone on my Slack team?</h5>
              <p>
                People are automatically added to your subscription the first time they use {Constants.AppName}.
                So you don't pay for anyone on your team who hasn't used {Constants.AppName}.
              </p>
            </Card>

            <Card className="pricing-details">
              <h5>I think I qualify for a discounted plan, how do I get it?</h5>
              <p>
                <Link to="/contact" className="no-decoration">Contact us</Link> and we'll
                work with you to determine if you qualify as a student or a teaacher.
              </p>
            </Card>

            <Card className="pricing-details">
              <h5>How do I get a discount for a not-for-profit?</h5>
              <p>
                <Link to="/contact" className="no-decoration">Contact us</Link> and we'll
                work with you to determine if you qualify. We have a few fixed monthly
                rates we charge non-profits based on team size. We're very flexible
                on finding a rate that works for you.
              </p>
            </Card>

            <Card className="pricing-details">
              <h5>We have one Slack for our whole company and would like to pay for just our team to use {Constants.AppName}. Is there a way to pay for just our team and not everyone on our Slack?</h5>
              <p>
                <Link to="/contact" className="no-decoration">Contact us</Link> now or during
                your free trial and we'll create a plan that works for your team.
              </p>
            </Card>
          </Container>

          <br /><br /><br /><br />
          <Separator color="gray" />
        </section>

        <Footer />
      </>
    );
  }
}

export default Landing;
