import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

class TeamInfo extends React.Component {
  render() {
    const profile = this.props.profile;

    const users = profile.admin
      ? <><br />Active Users: <strong>{profile.users}</strong></>
      : <></>;

    return (
      <>
        <Card className="shadow team-info">
          <CardBody>
            <CardTitle>
              <h5>Team Info</h5>
            </CardTitle>
            <div className="paragraph">
              Team: <strong>{profile.tname}</strong><br />
              Team ID: <strong>{profile.team}</strong>
              {users}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default TeamInfo;
