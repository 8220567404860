import React from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import Event from '../services/Event';
import Firebase from '../services/Firebase';
import Log from '../services/Log';
import Url from '../services/Url';
import Constants from '../Shared/Constants';
import Meta from '../Shared/Meta';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      token: this.props.match.params.token,
    };
  }

  attemptSignIn = async () => {
    if (this.state.token) {
      try {
        await Firebase.auth().signInWithCustomToken(this.state.token);
      } catch (e) {
        Event.error(Constants.SignInError);
        this.setState({
          error: Constants.SignInError,
        });
      }
    }
  }

  componentDidMount() {
    this.attemptSignIn();
  }

  render() {
    Log.message(`Loading Login`);

    // Once a user is logged in, the guard will take over

    // Error, redirect to error
    if (this.state.error) {
      const errorPath = `/error/${this.state.error}`;
      return (
        <Redirect to={errorPath} />
      );
    }

    // Default to spinner
    let displayContent = null;
    displayContent = <Spinner />;

    // No token, show sign up button
    if (!this.state.token) {
      displayContent =
        <div>
          <a href={Url.slackSignIn()}>
            <img
              alt="Sign in with Slack"
              src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
              srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
            />
          </a>
          <h5 className="jumbotron jumbotron-clear">Please sign in with your Slack account<br/> to access your team's account information</h5>
        </div>;
    }

    return (
      <>
        <Meta
          pageName="Login"
          ogTitle="Login"
          ogDescription={"Login to " + Constants.AppName}
        />

        <div className="full-height section-hero section-shaped">
          <div className="shape shape-style-4 shape-default"></div>
          <Container className="body-content">
            <div className="jumbotron jumbotron-clear align-items-center justify-content-center text-center">
              {displayContent}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Login;
