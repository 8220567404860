import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Row,
  UncontrolledCollapse,
} from "reactstrap";

import KarmuhTextLogo from '../assets/k-logo-square.svg';
import Firebase from '../services/Firebase';
import Constants from '../Shared/Constants';
import Links from '../Shared/Links';

// 'showSignOut' - true if showing the sign out button
class Menu extends React.Component {
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  signOut = async () => {
    await Firebase.auth().signOut();
  }

  render = (props) => {
    const button = this.props.showSignOut
      ? <Button className="btn-neutral" color="default" onClick={this.signOut}>
          <span className="nav-link-inner--text ml-1">
            {Constants.SignOutText}
          </span>
        </Button>
      : <Link to="/login">
          <Button className="btn-neutral" color="default" id="menu-signin">
            <span className="nav-link-inner--text ml-1" id="menu-signin">
              {Constants.SignInText}
            </span>
          </Button>
        </Link>;

    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom "
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={KarmuhTextLogo}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={KarmuhTextLogo}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar></Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <Links/>
                  <NavItem className="d-lg-block">
                    {button}
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default Menu;
