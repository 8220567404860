import Firebase from '../services/Firebase';

class Karmuh {
  constructor() {
    this.apiUrl = 'https://api.karmuh.app';
    this.apiVersion = 'v1';
    this.error = null; // Error code
    this.response = null; // JSON response
    this.status = null; // HTTP code
  }

  // API Routes

  async connectAccount(customerUuid) {
    const route = `connections`;
    const payload = { customer: customerUuid };

    this.clear();
    await this.call(route, payload);
  }

  async getReceipts() {
    const route = `receipts`;

    this.clear();
    await this.call(route);
  }

  async getProfile() {
    const route = `profile`;

    this.clear();
    await this.call(route);
  }

  async processActivation(cardToken) {
    const route = `activations`;
    const payload = { card: cardToken };

    this.clear();
    await this.call(route, payload);
  }

  // Helpers

  async call(route, payload) {
    const idToken = await Firebase.auth().currentUser.getIdToken(true);

    const data = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };

    if (payload) {
      data.body = JSON.stringify(payload);
      data.headers['Content-Type'] = 'application/json';
      data.method = 'POST';
    }

    const response = await fetch(`${this.url()}/${route}`, data);
    const json = await response.json();

    this.status = response.status;

    // Store error
    if (json.code) {
      this.error = json.code;
    }

    this.response = json;

    return;
  }

  clear() {
    this.error = null;
    this.response = null;
    this.status = null;
  }

  url() {
    return `${this.apiUrl}/${this.apiVersion}`;
  }
}

export default Karmuh;
