import React from 'react';
import { Button, Spinner } from 'reactstrap';

import Event from '../services/Event';
import Karmuh from '../services/Karmuh';
import Log from '../services/Log';
import Constants from '../Shared/Constants';

// bubbleError - the function used to bubble up the error
// bubblePayment - the function used to bubble up the payment
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null,
      elements: null,
      mounted: null,
      paymentProcessing: false,
      stripe: null,
    };
  }

  componentDidMount() {
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };


    const stripe = this.state.stripe || window.Stripe(Constants.StripePublicKey);
    const elements = stripe.elements();
    const card = this.state.card || elements.create('card', { style: style });
    let mounted = this.state.mounted;

    if (!mounted) {
      card.mount('#card-element');
      card.on('change', this.displayStripeError);
      mounted = true;
    }

    this.setState({
      card: card,
      elements: elements,
      mounted: mounted,
      stripe: stripe,
    });
  }

  displayStripeError(event) {
    const displayError = document.getElementById('card-errors');

    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  }

  processPayment = async () => {
    // Set the state to loading
    this.setState({
      paymentProcessing: true,
    });

    let result = null;

    // Stripe
    try {
      result = await this.state.stripe.createToken(this.state.card);
    } catch (e) {
      Log.message(e);
      this.displayStripeError(e);
      Event.error(Constants.StripeTokenError);
      this.setState({
        paymentProcessing: false,
      });
      return;
    }

    if (result.error) {
      this.displayStripeError(result);
      Event.error(Constants.StripeTokenError);
      this.setState({
        paymentProcessing: false,
      });
      return;
    }

    const token = result.token.id;

    // Set the payment in the profile, then set state to state
    try {
      const karmuh = new Karmuh();
      await karmuh.processActivation(token);

      if (karmuh.error) {
        Event.error(karmuh.error);
        this.setState({
          paymentProcessing: false,
        });
        this.props.bubbleError(karmuh.error);
      } else {
        this.setState({
          paymentProcessing: false,
        });
        this.props.bubblePayment(karmuh.response);
      }
    } catch (e) {
      Event.error(Constants.GeneralApiError);
      this.setState({
        paymentProcessing: false,
      });
      this.props.bubbleError(Constants.GeneralApiError);
    }

  }

  render() {
    return (
        <>
          <Spinner style={{ display: this.state.paymentProcessing ? "block" : "none" }} />
          <>
            <div id="card-element" style={{ display: this.state.paymentProcessing ? "none" : "block" }}></div>
            <div id="card-errors" role="alert"></div>
            <Button color="primary mt-4" type="button"
              id="submit-payment"
              style={{ display: this.state.paymentProcessing ? "none" : "block" }}
              onClick={() => this.processPayment()}
              disabled={this.state.paymentProcessing}
            >
              Submit
            </Button>
          </>
        </>
    );
  }
}

export default Payment;
