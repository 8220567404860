import React from 'react';
import { Button } from 'reactstrap';

import Url from '../services/Url';
import Constants from '../Shared/Constants';

class Connections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const profile = this.props.profile;
    const connections = profile.connections;

    const buttonText = connections.length
      ? 'Connect another team'
      : 'Connect a team';

    const profileList = connections.length
      ? <>
          <span>Your connected profiles</span><br />
          <ul>
            {connections.map(customer => <li key={customer.uuid}><strong>{customer.tname}</strong></li>)}
          </ul>
        </>
      : <>
          <br />No team profiles connected yet.
        </>;

    return (
      <>
        <div className="paragraph">
          Getting {Constants.AppName} in any of your connected team profiles updates
          your <strong>{Constants.GlobalKarmuhNameLowercase}</strong> score.<br />
          {profileList}

          <Button color="primary mt-4" type="button"
            id="connect-account"
            href={Url.slackConnectProfileSignIn()}
          >
            {buttonText}
          </Button>
        </div>
      </>
    );
  }
}

export default Connections;
