import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import Code from '../services/Code';
import Event from '../services/Event';
import Log from '../services/Log';
import Constants from '../Shared/Constants';
import Meta from '../Shared/Meta';
import Separator from '../Shared/Separator';

class Error extends React.Component {
  render() {
    Log.message(`Loading Error`);

    const {code} = this.props.match.params;

    Event.error(code);

    return (
      <>
        <Meta
          pageName="Something Went Wrong"
          ogTitle={Constants.AppName}
          ogDescription={Constants.Tagline}
        />

        <div className=" section-hero section-shaped">
          <div className="shape shape-style-3 shape-default"></div>
          <Container className="body-content">
            <h1>🐞 Something Went Wrong</h1>
            <h5 className="white mb-5 mt-5">{Code.map(code)}</h5>
            <Link to="/contact">Click Here</Link> to let us know.
            <br /><br />
            Or, simply go back <Link to="/">Home</Link>.
          </Container>

          <Separator />
        </div>
      </>
    );

  }
}

export default Error;
