import React from 'react';
import { Container } from 'reactstrap';

import Log from '../services/Log';
import Constants from '../Shared/Constants';
import Footer from '../Shared/Footer';
import Menu from '../Shared/Menu';
import Meta from '../Shared/Meta';
import Separator from '../Shared/Separator';

class Privacy extends React.Component {
  render() {
    Log.message(`Loading Privacy`);

    return (
      <>
        <Meta
          pageName="Privacy Policy"
          ogTitle="Privacy"
          ogDescription={Constants.AppName + "'s Privacy Policy"}
        />
        <Menu />

        <div className=" section-hero section-shaped">
          <div className="shape shape-style-3 shape-default"></div>
          <Container className="body-content">
            <section>
              <h1>Privacy Policy</h1>
              <p>
                Our approach to your privacy boils down to doing the
                right thing. You should never feel surprised about the information we know
                about you or when we contact you. Every interaction should be valuable
                to both you and us.
                If you ever feel like the information we know about you is surprising, please let us know.
              </p>
              <p>
                When it comes to being stewards of your information, we'll do the right
                thing and won't use your information without your permission. We strive to collect the least amount
                information as possible. Information takes up space, and we like space.
              </p>
              <p>Why should you trust us?</p>
            </section>

          </Container>

          <Separator />
        </div>

        <Container>
          <section>
            <br /><br />
            <br />
            <p>
              Because you support us with your financial support, and our relationship with you helps us achieve our
              company vision. It's not good to anger people you're in a relationship with. That's bad for our
              business, our personal happiness, and helping spread kindness throughout the world.
            </p>
            <p>
              <strong>If for any reason you don't agree with this privacy policy, please do not use any of our
                Services for any reason.</strong> Our <strong>Services</strong> are defined as
                anything {Constants.CompanyName} creates and owns.
            </p>

            <p>Here's a list of sections to learn more about your privacy: </p>
            <ul>
              <li>What we collect and why</li>
              <li>Where your information is stored and protections</li>
              <li>Cookies and how we use (hint: we don't eat them)</li>
              <li>Who has access to your data</li>
              <li>How you can control your data</li>
            </ul>

            <h2>First, a note about who you are</h2>
            <p>
              If you use {Constants.AppName}'s Services you are either a <strong>Member</strong> or
              an <strong>Administrator</strong>. Your {Constants.AppName} <strong>Community</strong> is made up
              of Members and Administrators. Administrators have access to all Member data and have control
              of your Community's settings and data.
            </p>
            <p>
              If you are not an Administrator and have data questions, request, or concerns please contact your
              Administrator so they can fulfill your needs. If you cannot contact your Administrator or have a
              dispute, please contact us at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>.
            </p>


            <h2>What we collect and why</h2>
            <p>
              When an Administer adds {Constants.AppName} to your chat platform (e.g. Slack), we collect
              information necessary for our Services to function. We also collect information if you give it to us.
            </p>
            <p>
              Any of the information we collect from you may be used in one of the following ways:
              (a) Personalize your experience.
              (b) Better respond to your individual needs.
              (c) Operate and improve our Services.
              (d) Improve customer service.
              (e) Contact you.
            </p>

            <p>Here is what we collect and why:</p>
            <ul>
              <li>
                Names and URLs of chat platforms you use with {Constants.AppName}
                <ul>
                  <li>
                    Used to display names in the product, identify your platform for support,
                    and associate information about your platform to itself.
                  </li>
                </ul>
              </li>
              <li>
                The number of people in your chat platform
                <ul>
                  <li>
                    We use this for product functionality and internal reporting.
                  </li>
                </ul>
              </li>
              <li>
                Your name and/or display name
                <ul>
                  <li>
                    To identify you in the product so we and people in your group can find you.
                  </li>
                </ul>
              </li>
              <li>
                Your avatar
                <ul>
                  <li>
                    This is a picture you've chosen to represent yourself. It's collected from
                    the picture you're using in the chat platform with {Constants.AppName}.
                  </li>
                </ul>
              </li>
              <li>
                Email address
                <ul>
                  <li>
                    Your account admin uses this to relate your information to other systems of record.
                  </li>
                  <li>
                    If you are an Administer, we may use your email to contact you regarding your product usage.
                    This includes things like billing, troubleshooting, cancellation reason, and welcoming you
                    to the product. We strive to send the least email possible.
                  </li>
                  <li>
                    If you are a Member, we won't email you unless you or your Administer gives us permission to
                    do so.
                  </li>
                </ul>
              </li>
              <li>
                Messages
                <ul>
                  <li>
                    In order to help you maintain privacy, our system is limited to collecting only the
                    keywords you associate {Constants.AppName} with. For us to store a keyword, it must:
                    <ul>
                      <li>
                        <strong>Slack</strong> &mdash;
                          (a) Have two or more consecutive +'s or -'s following it, this excludes
                          message reactions. (b) Be in a channel or group message that {Constants.AppName} has
                          been invited to as a member. i.e.
                          <ul>
                            <li>
                              "dogs++ are so cute!" would result in the collection of the keyword "dogs" only
                            </li>
                            <li>
                              "cats are cute too!" would result in the no keywords being collected
                            </li>
                          </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              If you have any questions about the information we collect please contact us
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>.
            </p>


            <h2>Where your information is stored and protections</h2>
            <h3>Security and storage</h3>
            <p>
              We use Google Cloud the hosting provider in the United States to store and protect
              your information. They are up to date and setup with the latest security standards and undergo recurring
              security assessments to protect your information.
            </p>
            <p>
              When you submit information via our service, your information is protected and encrypted though
              secure connections. We implement a variety of security measures to maintain the
              safety of your personal information.
            </p>
            <p>
              {Constants.AppName} may transfer your data to countries you don't live in. We offer European Union Model Clauses,
              known as Standard Contractual Clauses, to meet General Data Protection Regulation requirements for
              Administrators and Members who are citizens of the European Union. A copy of our data processing
              addendum, including Model Clauses, is available by contacting us.
            </p>
            <p>
              If you have any questions about data security and storage please contact us
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>
            </p>
            <br />


            <h3>How long do we retain data about you?</h3>
            <p>
              We use historical data about people who use our Services to generate internal research reports and for
              you to view historical information about your own usage.
            </p>
            <p>
              If you stop using the service, your data is also available to Administrators for your {Constants.AppName} account.
              Your old data can be used by Administrators to see historical {Constants.AppName} giving, messages you gave and
              received, and any other data collected about you.
            </p>
            <p>
              We remove your personal data after an Administrator removes and/or stops using our Services and
              sufficient time has passed to warrant data removal.
            </p>
            <p>
              The Administrators for your account are responsible for your data. In most circumstances, they must
              approve of any data removal or modifications. If you are a Member from the European Union and your
              Administrator will not request to remove your data please contact us
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>.
            </p>


            <h2>Cookies and how we use them (hint: we don't eat them)</h2>
            <p>
              Cookies are small files a site or its service provider transfers to your computer's hard
              drive through your Web browser (if you allow) that enables the sites or service
              providers systems to recognize your browser and capture and remember certain information.
            </p>
            <p>
              We use cookies to aggregate data about site traffic and site interaction so that we can
              offer better Services now and in the future. We may contract with third-party service
              providers to assist us in better understanding our site visitors and operate {Constants.AppName}.
              These service providers are not permitted to use the information collected on our
              behalf except to help us conduct and improve our Services.
            </p>

            <p>Here's a few examples of how we use cookies:</p>
            <ul>
              <li>
                To keep you logged in to the website.
              </li>
              <li>
                We use cookies for website surveys to learn more about changes we make
                to the product and your reaction to them.
              </li>
              <li>
                We use cookies to see how people interact with the website and our app in
                chat platforms to compute product usage metrics and monitor inflows of new prospects.
              </li>
              <li>
                We use cookies to help us contact people whose billing information is no longer valid.
              </li>
              <li>
                Our customer support software has cookies that enable onsite support widgets and help
                us better assist you.
              </li>
            </ul>
            <p>
              You have the right to accept or reject our cookies. You can exercise that right by not
              using our Services and/or using your web browser to accept or refuse cookies. You can visit your web
              browser's help menu to learn more about how to accept and reject cookies.
            </p>
            <p>If you ever have questions or feedback about cookies we use, please let us know
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>.
            </p>


            <h2>Who has access to your information</h2>
            <p>
              Your information will not be sold, exchanged, transferred, or given to any other company for any reason
              whatsoever, without your consent, other than for the express purpose of delivering the Services
              requested. We do not sell, trade, or otherwise transfer to outside parties your personally identifiable
              information.
            </p>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties your personally
              identifiable information. This does not include trusted third parties who assist
              us in operating our website, conducting our business, or servicing you, so long as
              those parties agree to keep this information confidential. An example is our payment
              processor. They may use your email address and name to send you receipts for your payments.
            </p>

            <ul>
              <li>
                Stripe (<a href="https://stripe.com">https://stripe.com</a>)
                <ul>
                  <li>Billing and payment processor for purchases. (Administrator contact data only)</li>
                </ul>
              </li>
              <li>
                Google Cloud (<a href="https://cloud.google.com">https://cloud.google.com</a>)
                <ul>
                  <li>Hosting provider for app and data storage.</li>
                </ul>
              </li>
              <li>
                Google Analytics (<a href="https://www.google.com/analytics">https://www.google.com/analytics</a>)
                <ul>
                  <li>Service analytics to track and analyze Service usage.</li>
                </ul>
              </li>
            </ul>

            <p>
              We may also release your information when we believe releasing it is appropriate to comply with the law,
              enforce our site policies, or protect ours or others rights, property, or safety. However,
              non-personally identifiable information may be provided to other parties for marketing, advertising, or
              other uses. An example of this would be creating an advertisement about how people who use {Constants.AppName} on
              Slack recognize each other X times per month on average. This is where you aggregate data to make it so
              the people behind it cannot be identified.
            </p>


            <h2>How you can control your data</h2>
            <p>
              Depending on if you are an Administrator or Member, you will have different rights regarding your data.
              If you are an Administrator, you can request for us to send, remove or modify any information about your
              account. To permanently remove your data, you must email us
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>, and we will remove your data
              within 30 days of notice.
            </p>
            <p>
              If you are a Member, you can export some of your personal data on your Profile page. If you would like
              to view, modify, or remove all or some of your data please contact your Administrator and have them
              email us to do so. If you don't know your Administrator or have questions please email us
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>.
            </p>


            <h2>Consent</h2>
            <p>
              If you do not consent to the collection, use or disclosure of your personal information as outlined in
              this policy, please do not provide any personal information to our Service or agree to our Terms of
              Service and Privacy Policy. If you have provided personal information and no longer consent to its use
              or disclosure as outlined herein, please notify us
              at <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>.
            </p>


            <h2>Data Protection Authority</h2>
            <p>
              Subject to applicable law, you have the right to (i) restrict {Constants.AppName}’s use of your Personal Data and
              (ii) lodge a complaint with your local data protection authority. If you are a resident of the European
              Economic Area and would like to lodge a complaint please contact your Data Protection Authority. You can
              find a list of Data Protection Authorities by clicking here: (<a
                href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</a>).
            </p>
            <p>
              Please contact us before lodging a complaint. We will do our best to meet your needs, adhere to
              applicable laws, our Terms of Service, and this Privacy Policy.
            </p>


            <h2>Changes to our Privacy Policy</h2>
            <p>
              If we decide to change our privacy policy, we will post those changes
              on this page.
              <br />
              <strong>Last Updated on January 16th, 2021</strong>
            </p>


            <h2>Contacting Us</h2>
            <p>
              If there are any questions regarding this privacy policy you may
              contact us here: <a href={Constants.MailToEmail}>{Constants.HelloEmail}</a>
            </p>
          </section>
        </Container>

        <Footer />
      </>
    );
  }
}

export default Privacy;
