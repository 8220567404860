import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import Loading from '../components/Loading';
import { AuthContext } from '../providers/Auth';
import Log from '../services/Log';

// Ensures the user is NOT logged in before accessing the route
// Otherwise redirects them to the logged in place
const SkipRoute = ({ component: Component, ...rest }) => {
  const { data } = useContext(AuthContext);

  Log.message(`Skip Route ${rest.path} - ${data.loading}, ${JSON.stringify(data.user)}`);

  return (
    <Route {...rest} render={(props) => (
      data.loading
        ? <Loading path={rest.path} />
        : data.user !== null
            ? <Redirect to='/account' />
            : <Component {...props} />
    )} />
  )
}

export default SkipRoute;
