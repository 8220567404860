const Constants = {
  AppName: 'Karmuh',
  AppNameLowercase: 'karmuh',
  Base64DecodingError: '126',
  ClientId: '1621154819363.1617866685797',
  CompanyName: `Karmuh`,
  ConnectingAccountError: '129',
  ConnectionMissingDataError: '128',
  ContactTagline: 'Need help with the Karmuh app? Come talk to us!',
  DaysFree: 60,
  Debug: false,
  GeneralApiError: '101',
  GlobalKarmuhName: 'Kosmic',
  GlobalKarmuhNameLowercase: 'kosmic',
  HelloEmail: 'hello@karmuh.app',
  JsonParsingError: '127',
  MailToEmail: 'mailto:hello@karmuh.app',
  ShortTagline: "Giving props couldn't be easier",
  SignInError: '123',
  SignInText: 'Sign In',
  SignOutText: 'Sign Out',
  SignUpText: 'Get Started',
  SiteImage: 'https://karmuh.app/social-img.jpg',
  SiteJson: '{"@context": "https://schema.org","@type": "Organization","logo":{"@type": "ImageObject","url": "https://karmuh.app/logo.png"},"url": "https://karmuh.app"}',
  SiteUrl: 'https://karmuh.app',
  StripeError: '120',
  StripeTokenError: '124',
  StripePublicKey: 'pk_live_51I9LNGKJRUI2L8GEj3JEok1XXfcZQWqMmWOXCLVCO5LwAB5zYW1tKHyfdiHJiFJbLrZRRQwhwgk2A9JP2xBXX5a000Dhe0v73a',
  SubscriptionPlanBulk: 'bulk',
  SubscriptionPlanFree: 'free',
  Tagline: 'Karmuh lets your colleagues know how much you appreciate them and brings your team together.',
  TaglineTwo: 'Appreciation improves performance, promotes learning, fosters creativity, and creates a better work environment. Add Karmuh to your workspace and start improving your team now!',
  TeamSizeLargeLimit: 500,
  TeamSizeLargePrice: 100,
  TeamSizeSmallLimit: 50,
  TeamSizeSmallPrice: 50,
  TierOneName: 'Starter',
  TierThreeName: 'Enterprise',
  TierTwoName: 'Growth',
};

export default Constants;
