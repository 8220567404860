import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

import Subscription from '../services/Subscription';
import Constants from '../Shared/Constants';
import Payment from '../Shared/Payment';

// bubbleError - the function used to bubble up the error
// bubbleSuccess - the function used to bubble up success
class SubscriptionInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  updatePayment = (payment) => {
    this.props.profile.payment = payment;
    this.props.profile.subscription.payment = payment.uuid;
    this.setState(this.state);
    this.props.bubbleSuccess('Payment successfully added');
  }

  render() {
    const profile = this.props.profile;

    if (!profile.installation) {
      return (<></>);
    }

    // Distinguish between admins and non-admins
    const header = <h5>Subscription Info</h5>;
    let body = '';

    if (!profile.admin) {
      // For non-admin users
      body =
        <>
          <div>
            Configuration for {Constants.AppName} is available for workspace admins
            only. Come back as an admin, or ask one of your workspace admins to
            sign in to configure {Constants.AppName}.
          </div>
        </>;
    } else {
      // Admins can see more

      if (profile.subscription.plan === Constants.SubscriptionPlanFree) {
        // Free plans won't have payments
        body =
          <>
            <div>
              Your <strong>{Constants.SubscriptionPlanFree}</strong> subscription for {Constants.AppName} is <strong>active</strong>.
            </div>
          </>;
      } else {
        // Set up billing dates
        const nextBillDate = Subscription.nextBillDate(profile.subscription, profile.payment);

        // Set up stripe element
        const stripeElement =
          <>
            <div className="stripe-payment">
              <Payment bubbleError={this.props.bubbleError} bubblePayment={this.updatePayment} />
            </div>
          </>;

        const isEnterprise =
          profile.users >= Constants.TeamSizeLargeLimit &&
          profile.subscription.plan === Constants.SubscriptionPlanBulk;

        body = profile.subscription?.payment
          ? <>
              <div>
                Your subscription for {Constants.AppName} is <strong>active</strong> and
                your {profile.payment.brand} card ending in <strong>{profile.payment.four}</strong> will
                be billed <strong>${profile.subscription.rate}</strong> next on {nextBillDate.toLocaleDateString()}.
              </div>
            </>
          : Subscription.inTrial(profile.subscription)
            ? isEnterprise
              ? <>
                  <div>
                    Your trial still has <strong>{Subscription.daysRemaining(profile.subscription)} days</strong> remaining.<br />
                    <Link to="/contact">Contact us</Link> to discuss configuring your <strong>{Constants.TierThreeName.toLocaleLowerCase()}</strong> rate
                    to continue using <strong>{Constants.AppName}</strong> when your trial ends.<br />
                  </div>
                </>
              : <>
                  <div>
                    Your trial still has <strong>{Subscription.daysRemaining(profile.subscription)} days</strong> remaining.<br />
                    Your rate with <strong>{profile.users}</strong> user{profile.users > 1 ? 's' : ''} will be <strong>${profile.subscription.rate}</strong>.<br />
                    Add a method of payment now to continue using <strong>{Constants.AppName}</strong> when your trial ends.<br />
                    {stripeElement}
                  </div>
                </>
            : isEnterprise
              ? <>
                  <div>
                    <Link to="/contact">Contact us</Link> to discuss configuring your <strong>{Constants.TierThreeName.toLocaleLowerCase()}</strong> rate
                    so you can sign up and keep using <strong>{Constants.AppName}</strong>.<br />
                  </div>
                </>
              : <>
                  <div>
                    Your rate with <strong>{profile.users}</strong> user{profile.users > 1 ? 's' : ''} is <strong>${profile.subscription.rate}</strong>.<br />
                    Sign up now to keep using <strong>{Constants.AppName}</strong>.<br />
                    {stripeElement}
                  </div>
                </>;
      }
    }


    return (
      <>
        <Card className="shadow subscription-info">
          <CardBody>
            <CardTitle>
              {header}
            </CardTitle>
            <div className="paragraph">
              {body}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SubscriptionInfo;
