import React from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../providers/Auth';
import Log from '../services/Log';
import Constants from '../Shared/Constants';
import Footer from '../Shared/Footer';
import Menu from '../Shared/Menu';
import Meta from '../Shared/Meta';
import Separator from '../Shared/Separator';

// 'path' - the path to route to when done loading (e.g. /login)
class Loading extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidUpdate() {
    Log.message('The component updated');
    const context = this.context;
    Log.message(context);

    // Update only if currently loading, and done loading
    if (this.state.loading && !context.data.loading) {
      this.setState({
        loading: context.data.loading,
      });
    }
  }

  render() {
    Log.message(`Loading Loading`);

    if (!this.state.loading) {
      if (this.props.path) {
        // We have a path, route to the path
        return (
          <Redirect to={this.props.path} />
        );
      } else {
        // No path passed in, error
        const errorPath = `/error/${Constants.SignInError}`;
        return (
          <Redirect to={errorPath} />
        );
      }
    }

    return (
      <>
        <Meta
          pageName="Loading"
          ogTitle="Loading"
          ogDescription={"Loading " + Constants.AppName}
        />
        <Menu />

        <div className="section-hero section-shaped">
          <div className="shape shape-style-2 shape-default"></div>

          <Container className="body-content">
            <Spinner />
            <h5 className="white">Just a second...</h5>
          </Container>

          <Separator color="gray" />

        </div>
        <Footer />
      </>
    );
  }
}

export default Loading;
