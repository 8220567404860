import React from 'react';
import MetaTags from 'react-meta-tags';

import Constants from '../Shared/Constants';

// pageName - to the right of 'Karmuh - ' in the title that shows up in the tab
// ogTitle - the title that shows up in the graph content
// ogDescription - the description that shows up in the graph content
class Meta extends React.Component {
  render() {
    const description = this.props.ogDescription || Constants.Tagline;
    const title = this.props.ogTitle || `${Constants.AppName} - ${Constants.ShortTagline}`;
    const name = this.props.pageName
      ? `${Constants.AppName} - ${this.props.pageName}`
      : `${Constants.AppName} - ${Constants.ShortTagline}`;

    return (
      <>
        <MetaTags>
          <title>{name}</title>
          <meta name="description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content={Constants.AppName} />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={Constants.SiteUrl} />
          <meta property="og:image" content={Constants.SiteImage} />
          <meta property="og:description" content={description} />
          <script type="application/ld+json">{Constants.SiteJson}</script>
        </MetaTags>
      </>
    );

  }
}

export default Meta;
