import React from 'react';
import {
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap';

import Constants from '../Shared/Constants';
import Links from '../Shared/Links';

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="4" sm="3" xs="12">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                    {Constants.AppName}
                </div>
              </Col>
              <Col md="8" sm="9" xs="12">
                <Nav className="nav-footer">
                  <Links all={true} />
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;
