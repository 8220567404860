import * as datefns from 'date-fns';

const Dates = {
  addOneMonth: (date) => {
    return datefns.addMonths(date, 1);
  },
  convertToThisMonth: (dateTime) => {
    let months = null;
    let newTime = null;
    let dateTimeWithMonthToConvertTo = new Date();

    if (datefns.isBefore(dateTime, dateTimeWithMonthToConvertTo)) {
      months = datefns.differenceInCalendarMonths(
        dateTimeWithMonthToConvertTo,
        dateTime
      );
      newTime = datefns.addMonths(dateTime, months);
    } else {
      months = datefns.differenceInCalendarMonths(
        dateTime,
        dateTimeWithMonthToConvertTo
      );
      newTime = datefns.subMonths(dateTime, months);
    }

    return newTime;
  },
  difference: (left, right) => {
    return datefns.differenceInCalendarDays(left, right);
  },
  inBetween: (earlier, middle, later) => {
    const earlierTime = earlier.getTime();
    const middleTime = middle.getTime();
    const laterTime = later.getTime();

    return earlierTime <= middleTime && middleTime <= laterTime;
  },
  isBefore: (left, right) => {
    return datefns.isBefore(left, right);
  },
};

export default Dates;
