import React from 'react';

const baseUrl = 'https://app.chatwoot.com';
const pathToScript = '/packs/js/sdk.js';
const token = 'soEj1yxXWBR7JdoxWMnoz75e';


const toggleChatBubbleDisplay = (showBubble = true) => {
  const classes = [
    'woot-widget-holder',
    'woot--bubble-holder',
  ];

  for (let c of classes) {
    const elements = document.getElementsByClassName(c);

    if (elements.length) {
      for (let e of elements) {
        if (e) {
          if (showBubble) {
            e.style.display = '';
          } else {
            e.style.display = 'none';
          }
        }
      }
    }
  }
}

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  componentDidMount() {
    (function(d,t) {
      let g = document.getElementById(baseUrl);
      if (!g) {
        g = d.createElement(t);
        const s = d.getElementsByTagName(t)[0];
        g.src = baseUrl + pathToScript;
        g.id = baseUrl;
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: token,
            baseUrl: baseUrl
          });
        }
      } else {
        toggleChatBubbleDisplay(true);
      }
    })(document,"script");
  }

  componentWillUnmount() {
    toggleChatBubbleDisplay(false);
  }

  render() {
    return (
      <></>
    );

  }
}

export default Chat;
