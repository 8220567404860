import Code from '../services/Code';

// https://developers.google.com/analytics/devguides/collection/ga4/events
// https://developers.google.com/gtagjs/reference/ga4-events
const Event = {
  analytics: ({ id, name, value }) => {
    try {
      // eslint-disable-next-line no-undef
      ga('event', 'web_vital', {
        action: name,
        interaction: false,
        label: id,
        value: Math.round(name === 'CLS' ? value * 1000 : value),
      });
    } catch (e) {
      //
    }
  },
  error: (code) => {
    try {
      // eslint-disable-next-line no-undef
      gtag('event', 'exception', {
        description: Code.map(code),
      });
    } catch (e) {
      //
    }
  },
};

export default Event;
