import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Account from './Account';
import Connect from './Connect';
import Contact from './Contact';
import Error from './Error';
import Landing from './Landing';
import Login from './Login';
import Privacy from './Privacy';
import Terms from './Terms';
import { Auth } from '../providers/Auth';
import Log from '../services/Log';
import GuardedRoute from '../Shared/GuardedRoute';
import SkipRoute from '../Shared/SkipRoute';
import Top from '../Shared/Top';

class Main extends React.Component {
  render() {
    Log.message(`Loading Main`);

    return (
      <Auth>
        <Router>
          <Top>
            <div>
              {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
              <Switch>
                <GuardedRoute path="/account" component={Account} />
                <GuardedRoute path="/connect/:hash" component={Connect} />
                <GuardedRoute path="/connect" component={Connect} />
                <SkipRoute path="/login/:token" component={Login} />
                <SkipRoute path="/login" component={Login} />
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/contact" component={Contact} />
                <Route path="/error/:code" component={Error} />
                <Route path="/error" component={Error} />
                <Route path="/" component={Landing} />
              </Switch>
            </div>
          </Top>
        </Router>
      </Auth>
    );
  }
}

export default Main;
