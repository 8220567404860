import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import Log from '../services/Log';
import Constants from '../Shared/Constants';
import Footer from '../Shared/Footer';
import Menu from '../Shared/Menu';
import Meta from '../Shared/Meta';
import Separator from '../Shared/Separator';

class Terms extends React.Component {
  render() {
    Log.message(`Loading Terms`);

    return (
      <>
        <Meta
          pageName="Terms of Service"
          ogTitle="Terms"
          ogDescription={Constants.AppName + "'s Terms of Service"}
        />
        <Menu />

        <div className=" section-hero section-shaped">
          <div className="shape shape-style-3 shape-default"></div>
          <Container className="body-content">
            <section>
              <h1>Terms of Service</h1>
              <p>
                These terms of service govern your use of our website and the services
                we provide; by using our website and services, you accept these terms and
                conditions in full. If you disagree with these terms of service or any
                part of these terms and service, you must not use our website.
                </p>
              <p>
                You must be 13 years of age or older to use {Constants.AppName} and it’s services. If you are under 13 years old
                  please do not use {Constants.AppName}. By using our website and services and by agreeing to these terms of service
                  you warrant and represent that you are at least 13 years of age. If you are accepting these Terms and
                  using {Constants.AppName} on behalf of a company, organization, or other legal entity, you represent and warrant
                  that you are authorized to do so.
                </p>
              <p>
                Our website uses cookies. By using our website and agreeing to these
                terms of service, you consent to our use of cookies in accordance with
                  the terms of our <Link to="/privacy">privacy policy</Link>.
                </p>
            </section>
          </Container>

          <Separator />
        </div>

        <Container>
          <section>

            <br /><br />
            <br />

            <h2>License to use website</h2>
            <p>
              Unless otherwise stated, {Constants.AppName} and/or its licensors own the intellectual property
              rights in the website and material on the website. Subject to the license below, all these
              intellectual property rights are reserved.
            </p>
            <p>
              You may view, download for caching purposes only, and print pages or other content from our website for
              your own personal use, subject to the restrictions set out below and elsewhere in these terms of
              service.
            </p>

            <h3>You must not:</h3>
            <p>
              Republish material from this website (including republication on another website).
              Sell, rent or sub-license material from the website. Reproduce, duplicate, copy or otherwise exploit
              material on this website for a commercial purpose.
            </p>
            <p>
              Where content is specifically made available for redistribution,
              it may only be redistributed.
            </p>

            <h2>Acceptable use</h2>
            <p>
              You must not use our website in any way that causes, or may cause,
              damage to the website or impairment of the availability or
              accessibility of the website; or in any way which is unlawful,
              illegal, fraudulent or harmful, or in connection with any unlawful,
              illegal, fraudulent or harmful purpose or activity.
            </p>
            <p>
              You must not use this website to copy, store, host, transmit, send,
              use, publish or distribute any material which consists of (or is linked to)
              any spyware, computer virus, Trojan horse, worm, keystroke logger,
              rootkit or other malicious computer software.
            </p>
            <p>
              You must not conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction and data harvesting) on or in relation to this
              website without our express written consent.
            </p>
            <p>
              You must not use this website to transmit or send unsolicited
              commercial communications. You must not use this website for any
              purposes related to marketing without our express written consent.
            </p>
          </section>
        </Container>
        <Footer />
      </>
    );
  }
}

export default Terms;
